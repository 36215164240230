import React from "react"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { ErrorBtn } from "../ErrorBtn"
import { IconUser } from "../icons"
import halvaCard from "../icons/halvaCard.png"
import { Wrapper } from "../Wrapper"

import * as styles from "./ErrorRepeat.module.scss"

const ErrorRepeat = () => {
  const onClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      placement: "error_screen",
      name: "view_products",
    })
  }

  return (
    <div className={styles.container}>
      <Wrapper variant="double">
        <IconUser />
        <h2 className={styles.title}>Привет, мы вас узнали!</h2>
        <p className={styles.subTitle}>
          Решение по прошлой заявке еще активно. Возвращайтесь&nbsp;к&nbsp;нам&nbsp;позже.
        </p>
      </Wrapper>
      <div className={styles.btnContainer}>
        <p className={styles.text}>
          А&nbsp;пока&nbsp;загляните&nbsp;в&nbsp;наш&nbsp;магазин&nbsp;—
          нам&nbsp;есть&nbsp;чем&nbsp;вас&nbsp;порадовать
        </p>
        <img className={styles.img} src={halvaCard} alt="halva card" width={202.55} height={185} />
        <ErrorBtn fullWidth onClick={onClick}>
          <a
            className={styles.link}
            href="https://halvacard.ru/shops/offers"
            target="_blank"
            rel="noopener noreferrer"
          >
            Посмотреть товары
          </a>
        </ErrorBtn>
      </div>
    </div>
  )
}

export default ErrorRepeat
