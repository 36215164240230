import clsx from "clsx"
import React from "react"
import * as styles from "./ErrorBtn.module.scss"

interface BtnProps {
  children: string | React.ReactElement
  fullWidth?: boolean
  onClick?: () => void
}
export const ErrorBtn = ({ children, fullWidth, onClick }: BtnProps) => (
  <button
    type="button"
    onClick={onClick}
    className={clsx(styles.btn, { [styles.full]: fullWidth })}
  >
    {children}
  </button>
)
