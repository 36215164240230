import React from "react"

export const IconRefresh = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.5 28.0004C56.5 43.4637 43.9643 56 28.5 56C13.0357 56 0.5 43.4637 0.5 28.0004C0.5 12.5363 13.0357 -1.24377e-06 28.5 -1.24377e-06C43.9643 -1.24377e-06 56.5 12.5363 56.5 28.0004Z"
      fill="#1868B9"
    />
    <path
      d="M22.1362 21.6362C22.9699 20.7978 23.9617 20.1331 25.054 19.6806C26.1464 19.2281 27.3176 18.9967 28.5 19H29.5V16L33.5 20L29.5 24V21H28.5C27.1155 21 25.7622 21.4105 24.611 22.1797C23.4599 22.9489 22.5627 24.0421 22.0328 25.3212C21.503 26.6003 21.3644 28.0078 21.6345 29.3656C21.9046 30.7235 22.5713 31.9708 23.5503 32.9497C24.5292 33.9287 25.7765 34.5954 27.1344 34.8655C28.4922 35.1356 29.8997 34.997 31.1788 34.4672C32.4579 33.9373 33.5511 33.0401 34.3203 31.889C35.0895 30.7378 35.5 29.3845 35.5 28H37.5C37.4999 29.78 36.9721 31.52 35.9831 33C34.9942 34.4799 33.5886 35.6334 31.9441 36.3146C30.2996 36.9958 28.4901 37.174 26.7443 36.8268C24.9985 36.4795 23.3949 35.6224 22.1362 34.3638C21.3004 33.5281 20.6374 32.536 20.1851 31.4441C19.7328 30.3522 19.5 29.1819 19.5 28C19.5 26.8181 19.7328 25.6478 20.1851 24.5559C20.6374 23.464 21.3004 22.4719 22.1362 21.6362Z"
      fill="white"
    />
  </svg>
)
