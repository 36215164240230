import clsx from "clsx"
import React from "react"
import * as styles from "./Wrapper.module.scss"

type WrapperProps = {
  children: React.ReactNode
  variant?: "double" | "single"
}

const Wrapper = ({ children, variant = "single" }: WrapperProps) => (
  <div className={clsx(styles.wrapper, styles[variant])}>{children}</div>
)
export default Wrapper
