import React from "react"
import { IconRefresh, IconSadEmoji } from "../icons"
import type { Items, Variant } from "./types"

export const items: Record<Variant, Items> = {
  technical: {
    Icon: <IconRefresh />,
    title: <>Что-то пошло не так</>,
    subTitle: (
      <>
        Обновите страницу — скорее всего, мы&nbsp;уже&nbsp;все&nbsp;починили.
        Если&nbsp;не&nbsp;помогло&nbsp;—&nbsp;попробуйте снова через 5 минут
      </>
    ),
    btnText: "Обновить страницу",
    eventName: "refresh_the_page",
  },
  reject: {
    Icon: <IconSadEmoji />,
    title: <>Нам очень жаль!</>,
    subTitle: <>К сожалению, сейчас мы не можем принять вашу&nbsp;заявку&nbsp;на&nbsp;Халву.</>,
    btnText: "Вернуться на главную",
    eventName: "go_back_to_the_main",
  },
}
