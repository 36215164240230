import React from "react"
import { ErrorRepeat } from "../components/ErrorsVariants"
import Layout from "../components/Layouts/base"
import { NewHeader } from "../components/Headers/NewHeader"

export default function ErrorRepeatData() {
  return (
    <div className="error-pages">
      <Layout>
        <NewHeader redLogoWithSovcombank withButton={false} noRightSection noShadow />
        <div className="error-pages-wrapper">
          <ErrorRepeat />
        </div>
      </Layout>
    </div>
  )
}
