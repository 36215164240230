import React from "react"

export const IconUser = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56 28.0004C56 43.4637 43.4645 56 28.0004 56C12.5363 56 0 43.4637 0 28.0004C0 12.5363 12.5363 0 28.0004 0C43.4645 0 56 12.5363 56 28.0004Z"
      fill="#76BC21"
    />
    <path
      d="M28 20C30.2091 20 32 21.7909 32 24C32 26.2091 30.2091 28 28 28C25.7909 28 24 26.2091 24 24C24 21.7909 25.7909 20 28 20ZM34 24C34 20.6863 31.3137 18 28 18C24.6863 18 22 20.6863 22 24C22 27.3137 24.6863 30 28 30C31.3137 30 34 27.3137 34 24ZM31.5 34C32.7595 34 34 35.2135 34 37C34 37.5523 34.4477 38 35 38C35.5523 38 36 37.5523 36 37C36 34.3682 34.1065 32 31.5 32H24.5C21.8935 32 20 34.3682 20 37C20 37.5523 20.4477 38 21 38C21.5523 38 22 37.5523 22 37C22 35.2135 23.2405 34 24.5 34H31.5Z"
      fill="white"
    />
  </svg>
)
