import React from "react"

export const IconSadEmoji = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56 28.0004C56 43.4637 43.4645 56 28.0004 56C12.5363 56 0 43.4637 0 28.0004C0 12.5363 12.5363 -1.24377e-06 28.0004 -1.24377e-06C43.4645 -1.24377e-06 56 12.5363 56 28.0004Z"
      fill="#FFC0C1"
    />
    <g clipPath="url(#clip0_2_613)">
      <path
        d="M24.501 27C23.6725 27 23.001 26.3284 23.001 25.5C23.001 24.6716 23.6725 24 24.501 24C25.3294 24 26.001 24.6716 26.001 25.5C26.001 26.3284 25.3294 27 24.501 27Z"
        fill="#D91D0B"
      />
      <path
        d="M31.5 27C30.6716 27 30 26.3284 30 25.5C30 24.6716 30.6716 24 31.5 24C32.3284 24 33 24.6716 33 25.5C33 26.3284 32.3284 27 31.5 27Z"
        fill="#D91D0B"
      />
      <path
        d="M28.01 18C33.53 18 38 22.48 38 28C38 33.52 33.53 38 28.01 38C22.48 38 18 33.52 18 28C18 22.48 22.48 18 28.01 18ZM28 36C32.42 36 36 32.42 36 28C36 23.58 32.42 20 28 20C23.58 20 20 23.58 20 28C20 32.42 23.58 36 28 36ZM28 30C30.33 30 32.32 31.45 33.12 33.5H31.45C30.76 32.31 29.48 31.5 28 31.5C26.52 31.5 25.25 32.31 24.55 33.5H22.88C23.68 31.45 25.67 30 28 30Z"
        fill="#D91D0B"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_613">
        <rect width="25" height="25" fill="white" transform="matrix(-1 0 0 1 40 16)" />
      </clipPath>
    </defs>
  </svg>
)
